
// Fixing scroll position in form validation auto focus on invalid input
const delay = 0;
const offset = 150;

document.addEventListener('invalid', function(e){
  $(e.target).addClass("invalid");
  $('html, body').animate({scrollTop: $($(".invalid")[0]).offset().top - offset }, delay);
}, true);
document.addEventListener('change', function(e){
  $(e.target).removeClass("invalid")
}, true);

// End