/**
 * Created by Azhar on 08-08-2017.
 */

var map;
var marker;
var infowindow ;
var geolat_elcoder;
var map_div_container;
var address_el, lat_el, lng_el;

function loadSchoolAddressMap() {
  this.map_div_container = document.getElementById('SchoolMap');
  this.address_el = document.getElementById('address_pin');
  this.lat_el = document.getElementById('SchoolLatitude');
  this.lng_el = document.getElementById('SchoolLongitude');

  // console.info(this.map_div_container, this.address_el, this.lat_el, this.lng_el);
  $.ajax({
    type: "GET",
    url: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDbUSuQ6MivWulRI-HM5BH1q0pSb3Kmxyw&libraries=places",
    success: function(){ initializeSchoolAddressMap(); },
    dataType: "script",
    cache: true
  });
}

function initializeSchoolAddressMap() {
  self = this;
  infowindow = new google.maps.InfoWindow();
  geocoder = new google.maps.Geocoder();
  var location = this.address_el;
  var autocomplete = new google.maps.places.Autocomplete(location);

  // Init map with either saved lat, lng or india centre lat, lng
  viewSchoolAddressMap(this.lat_el.value || 21.7679, this.lng_el.value || 78.8718);

  google.maps.event.addListener(autocomplete, 'place_changed', function () {
    var place = autocomplete.getPlace();
    self.address_el.value = place.name;
    self.lat_el.value = place.geometry.location.lat();
    self.lng_el.value = place.geometry.location.lng();
    $(self.address_el).trigger('change');
    self.address_el.dispatchEvent(new Event('change', { 'bubbles': false }));
    viewSchoolAddressMap(place.geometry.location.lat(),place.geometry.location.lng());
  });
  // this.address_el.removeAttribute('placeholder');
}

function viewSchoolAddressMap(lat,lng){
  // console.log('starting school with lat,lng', lat, lng);
  self = this;
  var myLatlng = new google.maps.LatLng(lat,lng);
  var myOptions = {
    zoom: 14,
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };
  // map = new google.maps.Map(document.getElementById("map"), myOptions);
  map = new google.maps.Map(this.map_div_container, myOptions);

  var marker = new google.maps.Marker({
    draggable: true,
    position: myLatlng,
    map: map,
    title: "School Location"
  });

  var formatted_address = 'Locate on Map';
  infowindow.setContent(formatted_address);
  infowindow.open(map, marker);

  google.maps.event.addListener(marker, 'dragend', function (event) {
    geocodePosition(marker.getPosition());
    self.lat_el.value = this.getPosition().lat();
    self.lng_el.value = this.getPosition().lng();
    self.address_el.dispatchEvent(new Event('change', { 'bubbles': false }));
  });

  function geocodePosition(pos) {
    var geocoder= new google.maps.Geocoder();
    geocoder.geocode({
      latLng: pos
    }, function(responses) {
      if (responses && responses.length > 0) {
        console.log('selected address', responses);
        self.address_el.value = responses[0].formatted_address;
        populateCityState(responses[0].address_components);
        self.address_el.dispatchEvent(new Event('change', { 'bubbles': false }));
      }
    });
  }
  
  function populateCityState(address_components) {
    // todo auto populate city and state
  }
}  
