
var seo_info_title = {
  '/school/auth/login' : {
    title: 'Login for Your School Dashboard | Edustoke',
    description:'Welcome to Edustoke! Explore your school dashboard through login credentials and connect with us.'
  },

  '/school/auth/reset' : {
    title: 'Reset Your Password with Registered Email | Edustoke',
    description:'Want to reset your dashboard login password? Fill with your registered email address.'
  },

  '/school/auth/pricing' : {
    title: 'Subscription Price and Plans for School Register | Edustoke',
    description:'Find pricing and plans for registering and subscription your school in Edustoke platform, get all details options and process.'
  },

  '/school/auth/claim' : {
    title: 'Claim Your School for Admission Leads | Edustoke',
    description:'Find the process of claim school on Edustoke dashboard; get more school admission leads and student enquiries.'
  },

  '/school/auth/register' : {
    title: 'Register Your School Online | Edustoke',
    description:'Looking for more school admissions and student enrollment in your school? Register your school with Edustoke or contact us.'
  },


}


